import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import SpecialItemRepository from "@/abstraction/repository/specialItemRepository";
const repository = new SpecialItemRepository();

export default function useUsersList() {
    // Use toast
    const toast = useToast()
    const specialItems = ref([])
    const pagination = ref([])

    // Table Handlers
    const tableColumns = [
        { key: 'item', sortable: true },
        { key: 'media', sortable: true },
        { key: 'link', sortable: true },
        { key: 'actions' },
    ]
    const perPage = ref(10)
    const currentPage = ref(1)
    const perPageOptions = [10, 25, 50, 100]
    const searchQuery = ref('')
    const sortBy = ref('id')
    const isSortDirDesc = ref(true)
    const roleFilter = ref(null)
    const planFilter = ref(null)
    const statusFilter = ref(null)

    const fetchSpecialItem = async (paginate = {page: 1, itemsPerPage: perPage.value}, filters= {}) => {
        const specialItemsList = await repository.radioIndex({filters, pagination: paginate})
        specialItems.value = specialItemsList.data
        pagination.value = specialItemsList.pagination
        return specialItems
    }

    // *===============================================---*
    // *--------- UI ---------------------------------------*
    // *===============================================---*

    const resolveUserRoleVariant = role => {
        if (role === 'subscriber') return 'primary'
        if (role === 'author') return 'warning'
        if (role === 'maintainer') return 'success'
        if (role === 'editor') return 'info'
        if (role === 'admin') return 'danger'
        return 'primary'
    }

    const resolveUserRoleIcon = role => {
        if (role === 'subscriber') return 'UserIcon'
        if (role === 'author') return 'SettingsIcon'
        if (role === 'maintainer') return 'DatabaseIcon'
        if (role === 'editor') return 'Edit2Icon'
        if (role === 'admin') return 'ServerIcon'
        return 'UserIcon'
    }

    const resolveUserStatusVariant = status => {
        if (status === 'pending') return 'warning'
        if (status === 'active') return 'success'
        if (status === 'inactive') return 'secondary'
        return 'primary'
    }

    return {
        fetchSpecialItem,
        tableColumns,
        perPage,
        currentPage,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        specialItems,
        pagination,

        resolveUserRoleVariant,
        resolveUserRoleIcon,
        resolveUserStatusVariant,

        // Extra Filters
        roleFilter,
        planFilter,
        statusFilter,
    }
}
